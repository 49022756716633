import Logo from 'assets/images/HR-X.png'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { useActivateUserMutation } from '@hr-root/hr-employee-react/src/generated/graphql'

export const ActivatedUser = () => {
  const { token }: any = useParams()

  let history = useHistory()
  const [
    ActivatedUser,
    { loading: mutationUpdateLoading, data },
  ] = useActivateUserMutation({
    onError: error => {
      console.log('error', error)
    },
    onCompleted: data => {
      setTimeout(() => {
        if (data?.activateUser) {
          history.push(`/create-password/${token}`)
        } else {
          console.log('mutation return false')
        }
      }, 2000)
    },
  })

  useEffect(() => {
    if (token) {
      ActivatedUser({
        variables: {
          token: token,
        },
      })
    }
  }, [token])

  return (
    <AuthLayout logo={Logo} image={Background}>
      {mutationUpdateLoading && <Loading />}
      {data?.activateUser ? (
        <div className="form-box">
          <span className="page-title">You're almost there!</span>
          <br />
          <span className="page-title">Redirecting...</span>
        </div>
      ) : (
        <div className="form-box">
          <span className="page-title">Something went wrong!</span>
        </div>
      )}
    </AuthLayout>
  )
}
