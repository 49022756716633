import * as yup from 'yup'

// export const PurchaseReqSchema = yup.object().shape({
//   requestedBy: yup.string().required(SystemMsgs.requestedBy()),
//   contactPerson: yup.string().required(SystemMsgs.contactPerson()),
//   mobileNo: yup.string().required(SystemMsgs.mobileNo()),
//   specialInstruction: yup.string().required(SystemMsgs.specialInstruction()),
// })

export const SupplierSchema = yup.object().shape({
  companyName: yup.string().required(),
  companyRegNo: yup.string().required(),
  addressOne: yup.string().required(),
  addressTwo: yup.string().required(),
  country: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  postCode: yup.string().required(),
  officeNo: yup.string().required(),
  emailAddress: yup.string().required(),
  bankName: yup.string().required(),
  accountHolderNo: yup.string().required(),
  bankAccountNo: yup.string().required(),
  taxType: yup.string().required(),
  currency: yup.string().required(),
  creditTerm: yup.number().required(),
  description: yup.string().required(),
  supplierStatus: yup.string().required(),
  supplierRating: yup.string().required(),
  contactName: yup.string().required(),
  designation: yup.string().required(),
  mobileNo: yup.string().required(),
  email: yup.string().required(),
})

export const LoginSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
  // product: yup.string().required(),
})

export const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup
    .string()
    .required('New password is required')

    .min(8, 'Password must be at least 8 characters')
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase character'
    )
    .matches(
      /(?=.*[a-z])/,
      'Password must contain at least one lowercase character'
    )
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(
      /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
      'Password must contain at least one symbol'
    ),

  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
})

export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().required(),
})
export const ResetPasswordSchema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
